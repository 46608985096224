.social {
    position: absolute;
    top: 10px;
    left: 30px;
    display: flex;
    gap: 25px;
    img {
      height: 48px;
      width: 48px;
    }
    &.mobile {
      width: calc(100% - 60px);
      justify-content: center;
    }
  }
  
  h1 {
    font-size: 64px;
    color: #9c6e5d;
  }
  
  .cinco {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 42px;
    gap: 32px;
    color: #9c6e5d;
    text-align: center;
    h2 {
      font-family: "neue-machina-regular", serif;
    }
    img {
      width: 100%;
      height: auto;
      max-width: 350px;
    }
    &.mobile {
      padding-top: 75px;
    }
  }
  
  .link {
    width: 100%;
    max-width: 460px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #9c6e5d;
    border-radius: 20px;
    .url {
      margin-right: auto;
      font-size: 14px;
      padding: 0 12px;
      font-family: "neue-machina-regular", serif;
    }
    button {
      font-size: 20px;
      width: 90px;
      color: #f8e0b0;
      background: #9c6e5d;
      cursor: pointer;
      border: 0;
      font-family: "neue-machina-light", serif;
      padding-bottom: 3px;
      border-radius: 6px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  
  .memes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 100px 40px 40px 40px;
    gap: 64px;
    text-align: center;
    .memeBlock {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
    img {
      width: 210px;
      height: 210px;
    }
  }
  