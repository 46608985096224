@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,100..900;1,100..900&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Bitter", serif;
    font-weight: 900;
  }
  
  body {
    background: #FBECCF;
  }
  
  @font-face {
    font-family: "neue-machina";
    src: url("./assets/Bitter.ttf");
    font-weight: 900;
    font-style: bold;
  }
  
  @font-face {
    font-family: "neue-machina-regular";
    src: url("./assets/HappyMonkey-Regular.ttf");
    font-weight: 300;
    font-style: normal;
  }
  
  ::-webkit-scrollbar {
    width: 4px;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #9c6e5d;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f8e0b0;
  }
  